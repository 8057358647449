<template></template>

<script>
import { url_v1, url_v2 } from "../config.js";
import { portal_url, base_url } from "../api.js";
import Axios from "axios";

export default {
  name: "Logout",

  data() {
    return {
      getError: "",
    };
  },

  created() {
    // this.doLogout(token);
    const token = this.$route.params.token;
    this.doLogout(token);
  },

  methods: {
    // doLogout(token) {
    //   Axios.post(
    //     `${portal_url}/api/logout`,
    //     {},
    //     {
    //       headers: {
    //         Authorization: `Bearer ${token}`,
    //       },
    //     }
    //   )
    //     .then((res) => {
    //       window.location.href = "/";
    //     })
    //     .catch((error) => {
    //       if (typeof error.response.data.message !== "undefined") {
    //         this.getError = error.response.data.message;
    //       }
    //       window.location.href = "/";
    //     });
    // },

    async doLogout(token) {
      try {
        await Axios.post(
          `${portal_url}/api/logout`,
          {},
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        window.location.href = "/";
      } catch (error) {
        if (typeof error.response.data.message !== "undefined") {
          this.getError = error.response.data.message;
        }
        window.location.href = "/";
      }
    },
  },
};
</script>
