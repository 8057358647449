<template>
  <div class="parent d-flex justify-content-center align-items-center">
    <div class="verify-success text-center">
      <h1>Account created successfully</h1>
      <br />
      <img src="../assets/img/register-success.png" alt="" width="400" />
      <br />
      <p>
        Thank you for trusting us, and enjoy using the services and benefits of
        gps.id Super Spring
      </p>
      <div class="buttons text-center">
        <button
          class="next-btn w-100 my-2 vs-con-loading__container"
          @click="toLogin"
        >
          Back to Login
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },

  created() {},

  mounted() {},

  methods: {
    toLogin() {
      this.$router.push({ name: "login" });
    },
  },
};
</script>

<style scoped>
.verify-success h1 {
  color: #1a1a1a;
  font-size: 46px;
  font-family: Poppins;
  font-weight: 600;
  text-transform: capitalize;
}

.verify-success p {
  color: #7a7a7a;
  font-size: 20px;
}
</style>
