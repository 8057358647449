<template>
  <div class="parent">
    <div class="left-content">
      <BannerRegister />
    </div>
    <div class="right-content m-auto">
      <div class="steps mb-3" v-show="currentStep !== 1">
        <div class="create-acc-step">
          <div
            class="step"
            :class="{
              'active-step': currentStep >= 0,
            }"
          >
            <span>1</span>
          </div>
          <span
            class="step-text"
            :class="{
              'active-text': currentStep >= 0,
            }"
          >
            Create Account
          </span>
        </div>

        <div
          class="line-step"
          :class="{ 'active-line': currentStep >= 3 }"
        ></div>

        <div class="add-unit-step">
          <div class="step" :class="{ 'active-step': currentStep >= 3 }">2</div>
          <span class="step-text" :class="{ 'active-text': currentStep >= 3 }">
            Add Unit
          </span>
        </div>

        <div
          class="line-step"
          :class="{ 'active-line': currentStep >= 4 }"
        ></div>

        <div class="user-info-step">
          <div class="step" :class="{ 'active-step': currentStep >= 4 }">3</div>
          <span class="step-text" :class="{ 'active-text': currentStep >= 4 }">
            User Information
          </span>
        </div>
      </div>

      <div class="content-wrapper px-5">
        <div v-show="currentStep === 0" class="tab create-account">
          <div>
            <button
              class="google-signup p-3 w-100 vs-con-loading__container"
              @click="signUpWithGoogle"
            >
              <span class="google-icon"></span>
              <span id="btnGoogle" class="button-text"
                >Sign Up with Google</span
              >
            </button>
          </div>

          <hr />

          <div class="email d-flex flex-column mt-3">
            <span class="label mb-2">Email</span>
            <div :class="['input-box', 'p-3', { filled: inputFilled }]">
              <input
                type="email"
                placeholder="Input your email"
                v-model="email"
              />
            </div>
            <span
              v-show="errorStat == 'email'"
              class="input-note mt-2"
              style="color: red"
            >
              {{ errorMessage }}
            </span>
            <!-- <span v-else class="input-note mt-2">
              You will receive verification email for register your account.
            </span> -->
          </div>

          <div class="password d-flex flex-column my-3">
            <span class="label mb-2">Password</span>
            <div
              :class="[
                'input-box',
                'p-3',
                'd-flex',
                'justify-content-between',
                'align-items-center',
                { filled: password.length > 0 },
              ]"
            >
              <input
                :type="passwordType"
                placeholder="Input your password"
                v-model="password"
              />

              <div class="toggle-password" @click="togglePassword">
                <img
                  v-if="passwordType === 'text'"
                  src="../assets/img/eye-open.svg"
                  alt=""
                />
                <img v-else src="../assets/img/eye-closed.svg" alt="" />
              </div>
            </div>
            <span
              v-show="errorStat == 'password'"
              class="input-note mt-2"
              style="color: red"
            >
              {{ errorMessage }}
            </span>
          </div>

          <div class="confirm-password d-flex flex-column my-3">
            <span class="label mb-2">Confirm Password</span>
            <div
              :class="[
                'input-box',
                'p-3',
                'd-flex',
                'justify-content-between',
                'align-items-center',
                { filled: confirmPassword.length > 0 },
              ]"
            >
              <input
                :type="confirmPasswordType"
                placeholder="Confirm your password"
                v-model="confirmPassword"
              />

              <div class="toggle-password" @click="toggleConfirmPassword">
                <img
                  v-if="confirmPasswordType === 'text'"
                  src="../assets/img/eye-open.svg"
                  alt=""
                />
                <img v-else src="../assets/img/eye-closed.svg" alt="" />
              </div>
            </div>
            <span
              v-show="errorStat == 'confirm_password'"
              class="input-note mt-2"
              style="color: red"
            >
              {{ errorMessage }}
            </span>
            <!-- <button @click="storeEmail" class="mt-5"></button> -->
          </div>
        </div>

        <div v-show="currentStep === 1" class="tab email-verification">
          <div
            class="verify-email d-flex flex-column align-items-center justify-content-center text-center"
          >
            <h1>Verify your email</h1>
            <p>You have to verify your email to continue the registration</p>
            <p>
              We've sent you verification link to
              <span>{{ email }}</span
              >. Please check your email and finish the verification
            </p>

            <img src="../assets/img/verify-email.png" alt="" width="200" />

            <p>Didn't receive any email? Check spam folder or</p>
            <ul>
              <li>
                <a href="/register">Change email address</a>
              </li>
              <li>
                <b-spinner
                  v-if="loadResend"
                  variant="primary"
                  label=""
                ></b-spinner>
                <a v-else href="javascript:" @click="resendStoreEmail()"
                  >Resend verification link</a
                >
              </li>
            </ul>
          </div>
        </div>

        <div v-show="currentStep === 2" class="tab email-verified">
          <div class="verify-success text-center">
            <h1>{{ respon }}</h1>
            <br />
            <img src="../assets/img/verify-success.png" alt="" width="330" />
            <br />
            <p>
              <!-- Thank you for finishing your email verification. You can continue
              register your account. -->
              <!-- {{ respon }} -->
            </p>
          </div>
        </div>

        <div v-show="currentStep === 3" class="tab add-unit">
          <div class="unit-list" v-if="unitList.length > 0">
            <div class="total-unit mb-2">
              <span>Total</span>
              <span>{{ unitList.length }} Unit</span>
            </div>
            <div class="unit-card-wrapper mb-2">
              <div
                class="unit-card my-2"
                v-for="(unit, index) in unitList"
                :key="unit.id"
              >
                <div
                  class="unit-card-top d-flex align-items-center justify-content-between"
                >
                  <div class="unit-item-left d-flex align-items-center gap-2">
                    <img
                      :src="unit.vehicle_icon_front.acc_on"
                      alt=""
                      width="40"
                    />
                    <span>{{ unit.plate_no }} - {{ unit.vehicle_name }}</span>
                  </div>
                  <div class="unit-item-right">
                    {{ unit.gps_type }} {{ unit.gps_name }}
                  </div>
                </div>
                <div
                  class="unit-card-bottom d-flex align-items-center justify-content-between"
                >
                  <div class="unit-item-left">
                    <span>GSM Number</span>
                    <span>{{ unit.gsm_number }}</span>
                  </div>
                  <div class="unit-item-center">
                    <span>IMEI Number</span>
                    <span>{{ unit.imei }}</span>
                  </div>
                  <div class="unit-item-right" title="Remove from list">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-trash3"
                      viewBox="0 0 16 16"
                      style="color: #ff6969; cursor: pointer"
                      @click="delete_unit(index)"
                    >
                      <path
                        d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z"
                      />
                    </svg>
                  </div>
                </div>
              </div>
              <button
                class="add-unit-btn w-100 d-flex align-items-center gap-2"
                @click="toggleUnitSidebar"
              >
                <img src="../assets/img/circle-plus-blue.svg" alt="" />
                <span>Add Unit</span>
              </button>
            </div>
          </div>
          <div class="mb-2" v-if="unitList.length === 0">
            <button
              class="add-unit-btn w-100 d-flex align-items-center gap-2"
              @click="toggleUnitSidebar"
            >
              <img src="../assets/img/circle-plus-blue.svg" alt="" />
              <span>Add Unit</span>
            </button>
            <div class="no-unit">
              <img
                src="../assets/img/hero-img-add-unit.png"
                alt=""
                width="200"
              />
              <p>
                You need at least one unit of GPS.ID to continue the
                registration.
              </p>
            </div>
          </div>

          <b-sidebar
            id="sidebar-backdrop"
            backdrop-variant="dark"
            no-header-close
            backdrop
            shadow
            right
            v-model="unitForm"
            width="560px"
          >
            <div class="unit-form-wrapper">
              <div class="imei d-flex flex-column mt-3">
                <span class="label mb-2">Imei</span>
                <div :class="['input-box', 'p-3', { filled: imei.length > 0 }]">
                  <input
                    class="w-100"
                    type="number"
                    placeholder="Input your imei"
                    v-model="imei"
                  />
                </div>
                <template>
                  <!-- <ul v-if="!errorStateImei" class="input-note mt-2">
                    <li>
                      You can see your IMEI number on the unit package box
                    </li>
                  </ul> -->
                  <ul
                    class="input-note mt-2"
                    :style="!imeiCheckSuccess ? 'color: red' : ''"
                  >
                    <li>{{ imeiCheckMsg }}</li>
                  </ul>
                </template>
              </div>

              <div class="gsm d-flex flex-column">
                <span class="label mb-2">GSM Number</span>
                <div
                  :class="[
                    'input-box',
                    'p-3',
                    { filled: gsm_number.length > 0 },
                  ]"
                >
                  <input
                    class="w-100"
                    type="number"
                    placeholder="Input your GSM number"
                    v-model="gsm_number"
                  />
                </div>
                <template>
                  <!-- <ul v-if="!errorStateImei" class="input-note mt-2">
                    <li>You can see your GSM number on unit package box</li>
                  </ul> -->
                  <ul
                    class="input-note mt-2"
                    :style="!gsmCheckSuccess ? 'color: red' : ''"
                  >
                    <li>{{ gsmCheckMsg }}</li>
                  </ul>
                </template>
              </div>

              <div class="vehicle">
                <div class="v-plate d-flex flex-column">
                  <span class="label mb-2">Plate Number</span>
                  <div
                    :class="[
                      'input-box',
                      'p-3',
                      { filled: plate_no.length > 0 },
                    ]"
                  >
                    <input
                      class="w-100"
                      type="text"
                      placeholder="ex: B 1234 XYZ"
                      v-model="plate_no"
                    />
                  </div>
                  <template v-if="plateCheckMsg">
                    <ul class="input-note mt-2" style="color: red">
                      <li>{{ plateCheckMsg }}</li>
                    </ul>
                  </template>
                </div>
                <div class="v-name d-flex flex-column">
                  <span class="label mb-2">Vehicle Name</span>
                  <div
                    :class="[
                      'input-box',
                      'p-3',
                      { filled: vehicle_name.length > 0 },
                    ]"
                  >
                    <input
                      class="w-100"
                      type="text"
                      placeholder="Input your vehicle name"
                      v-model="vehicle_name"
                    />
                  </div>
                  <template v-if="vehNameCheckMsg">
                    <ul class="input-note mt-2" style="color: red">
                      <li>{{ vehNameCheckMsg }}</li>
                    </ul>
                  </template>
                </div>
              </div>

              <div class="vehicle-type d-flex flex-column mt-3">
                <span class="label mb-2">Vehicle Type</span>
                <div :class="['input-box', { filled: vehicle_type !== null }]">
                  <v-select
                    class="style-chooser"
                    :options="vehicleTypeList"
                    v-model="vehicle_type"
                    label="title"
                    placeholder="Select vehicle type"
                  ></v-select>
                </div>
                <template v-if="vehTypeCheckMsg">
                  <ul class="input-note mt-2" style="color: red">
                    <li>{{ vehTypeCheckMsg }}</li>
                  </ul>
                </template>
              </div>

              <div class="vehicle-icon d-flex flex-column mt-3">
                <span class="label mb-2">Vehicle Icon</span>
                <div :class="['input-box', { filled: vehicle_icon !== null }]">
                  <v-select
                    class="style-chooser"
                    :options="vehicleIconList"
                    v-model="vehicle_icon"
                    label="title"
                    placeholder="Select vehicle icon"
                  >
                    <template slot="option" slot-scope="option">
                      <img
                        style="width: 50px; height: 50px"
                        :src="option.parking"
                      />
                      <img
                        style="width: 50px; height: 50px"
                        :src="option.acc_on"
                      />
                      <img
                        style="width: 50px; height: 50px"
                        :src="option.lost"
                      />
                      <img
                        style="width: 50px; height: 50px"
                        :src="option.alarm"
                      />
                    </template>
                  </v-select>
                </div>
                <template v-if="vehIconCheckMsg">
                  <ul class="input-note mt-2" style="color: red">
                    <li>{{ vehIconCheckMsg }}</li>
                  </ul>
                </template>
              </div>

              <div class="actions w-100 d-flex align-items-center gap-2 mt-3">
                <button class="btn-cancel" @click="cancelUnit">Cancel</button>
                <button class="btn-submit-unit" @click="submitUnit">
                  <b-spinner
                    v-if="loadAddUnit"
                    variant="light"
                    label=""
                  ></b-spinner>
                  <span v-else>Add Unit</span>
                </button>
              </div>
            </div>
          </b-sidebar>
        </div>

        <div v-show="currentStep === 4" class="tab user-info">
          <div class="customer-type d-flex flex-column">
            <span class="label mb-2">Customer Type</span>
            <div class="input-box p-3 d-flex align-items-center gap-3">
              <div class="indiv-radio d-flex align-items-center gap-2">
                <input
                  type="radio"
                  name="customerType"
                  id="individual"
                  value="0"
                  v-model="customerType"
                />
                <label for="individual">Individual</label>
              </div>
              <div class="comp-radio d-flex align-items-center gap-2">
                <input
                  type="radio"
                  name="customerType"
                  id="company"
                  value="1"
                  v-model="customerType"
                />
                <label for="company">Company</label>
              </div>
            </div>
          </div>

          <div class="name d-flex flex-column mt-3" v-if="customerType === '1'">
            <span class="label mb-2">Company Name</span>
            <div class="input-wrapper d-flex align-items-center gap-2">
              <div :class="['input-box']" style="width: 25%">
                <v-select
                  class="style-chooser"
                  :options="prenameCoList"
                  v-model="prenameco"
                  placeholder="-"
                ></v-select>
              </div>

              <div :class="['input-box', 'p-3']" style="width: 75%">
                <input
                  class="w-100"
                  type="text"
                  placeholder="Type your company name"
                  v-model="company_name"
                />
              </div>
            </div>
            <template v-if="companyNameCheckMsg">
              <ul class="input-note mt-2" style="color: red">
                <li>{{ companyNameCheckMsg }}</li>
              </ul>
            </template>
          </div>

          <div class="name d-flex flex-column mt-3">
            <span class="label mb-2">Name</span>
            <div class="input-wrapper d-flex align-items-center gap-2">
              <div
                :class="['input-box', { filled: pronounce !== null }]"
                style="width: 25%"
              >
                <v-select
                  class="style-chooser"
                  :options="pronounceList"
                  v-model="pronounce"
                  placeholder="-"
                ></v-select>
              </div>

              <div
                :class="['input-box', 'p-3', { filled: name.length > 0 }]"
                style="width: 75%"
              >
                <input
                  class="w-100"
                  type="text"
                  placeholder="Type your name"
                  v-model="name"
                />
              </div>
            </div>
            <template v-if="fullNameCheckMsg">
              <ul class="input-note mt-2" style="color: red">
                <li>{{ fullNameCheckMsg }}</li>
              </ul>
            </template>
          </div>

          <div class="phone d-flex flex-column mt-3">
            <span class="label mb-2">Phone Number</span>
            <div class="input-wrapper d-flex align-items-center gap-2">
              <div
                :class="['input-box', { filled: phone_number_code !== null }]"
                style="width: 25%"
              >
                <v-select
                  class="style-chooser"
                  :options="phoneNumberList"
                  v-model="phone_number_code"
                ></v-select>
              </div>

              <div
                :class="[
                  'input-box',
                  'p-3',
                  { filled: phone_number.length > 0 },
                ]"
                style="width: 75%"
              >
                <input
                  class="w-100"
                  type="number"
                  placeholder="Type your phone number"
                  v-model="phone_number"
                />
              </div>
            </div>
            <template v-if="phoneNumberCheckMsg">
              <ul class="input-note mt-2" style="color: red">
                <li>{{ phoneNumberCheckMsg }}</li>
              </ul>
            </template>
          </div>

          <div class="referral d-flex flex-column my-3">
            <span class="label mb-2">Referral</span>

            <div :class="['input-box', 'p-3', { filled: referral.length > 0 }]">
              <input
                class="w-100"
                type="text"
                placeholder="Referral Username or Email"
                v-model="referral"
              />
            </div>
            <ul class="input-note mt-2">
              <li>Input referral if you have a one (Optional)</li>
            </ul>
          </div>

          <div class="tnc d-flex my-2">
            <vs-checkbox name="checkbox" v-model="agree_tnc" color="#1DA1F2">
              I agree to
            </vs-checkbox>
            <a
              href="https://gps.id/help/eula.html"
              target="_blank"
              style="color: #1da1f2"
            >
              SUPERSPRING License and Agreement
            </a>
          </div>
        </div>

        <div class="buttons text-center">
          <template v-if="respon_status">
            <button
              id="btnStoreEmail"
              class="next-btn w-100 my-2 vs-con-loading__container"
              :class="{ 'disabled-btn': isButtonDisabled }"
              @click="nextStep"
              v-show="currentStep !== 1"
              :disabled="isButtonDisabled"
            >
              <b-spinner
                v-if="load_store_all && currentStep == 4"
                variant="light"
                label=""
              ></b-spinner>
              <template v-else>{{ nextButtonText }}</template>
            </button>
            <button
              class="prev-btn w-100"
              @click="previousStep"
              v-show="currentStep > 2"
            >
              Back
            </button>
          </template>
          <!-- <button
            class="prev-btn w-100"
            @click="buyGps"
            v-show="currentStep === 2"
          >
            Buy GPS
          </button> -->
          <p class="my-2">Have an Account? <a href="/">Login</a></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BannerRegister from "./BannerRegister.vue";
import vSelect from "vue-select";
import { portal_url, base_url, api_key, api_url } from "../api.js";
import Axios from "axios";
import jwt_decode from "jwt-decode";

import {
  getAuth,
  onAuthStateChanged,
  signInWithPopup,
  GoogleAuthProvider,
  signOut,
} from "firebase/auth";

import { firebaseApp, firebaseAuth } from "@/main";

export default {
  name: "Register",

  components: {
    BannerRegister,
    vSelect,
  },

  data() {
    return {
      steps: ["Create Account", "Add Unit", "User Information"],
      // currentStep: 0,
      currentStep: Number(this.$route.query.currentStep) || 0,
      loading: false,
      apiKey: "",
      token_register: "",
      passwordType: "password",
      confirmPasswordType: "password",
      email: "",
      password: "",
      confirmPassword: "",

      errorState: false,
      errorMessage: "",
      errorStat: "",

      inputFilled: false,

      unitForm: false,

      imei: "",
      gsm_number: "",
      plate_no: "",
      vehicle_name: "",

      vehicle_icon: null,
      vehicleOptions: ["Icon 1", "Icon 2", "Icon 3"],

      vehicle_type: null,
      vehicleTypeOptions: ["Car", "Motorcycle", "Truck"],

      unitList: [],
      vehicleTypeList: [],
      vehicleIconList: [],
      branchList: [],

      name: "",
      customerType: 0,
      phone_number: "",
      referral: "",
      agree_tnc: "",

      imeiDetail: [],
      imeiCheckSuccess: true,
      imeiCheckMsg: "You can see your IMEI number on the unit package box",
      errorStateImei: false,

      gsmDetail: [],
      gsmCheckSuccess: true,
      gsmCheckMsg: "You can see your GSM number on unit package box",
      errorStateGsm: false,

      plateCheckMsg: "",
      vehNameCheckMsg: "",
      vehTypeCheckMsg: "",
      vehIconCheckMsg: "",

      fullNameCheckMsg: "",
      companyNameCheckMsg: "",
      phoneNumberCheckMsg: "",

      loadResend: false,
      loadAddUnit: false,

      pronounce: "MR",
      pronounceList: ["MR", "MRS"],
      prenameCoList: [
        {
          value: "PT",
          label: "PT",
        },
        {
          value: "CV",
          label: "CV",
        },
        {
          value: "TBK",
          label: "TBK",
        },
        {
          value: "Corp",
          label: "Corp",
        },
        {
          value: "Inc",
          label: "Inc",
        },
        {
          value: "UD",
          label: "UD",
        },
        {
          value: "Ltd",
          label: "Ltd",
        },
      ],

      prenameco: {
        value: "PT",
        label: "PT",
      },

      company_name: "",

      branch: 0,

      customer_type: null,

      phone_number_code: "+62",
      phoneNumberList: ["+62"],

      verify_state: false,
      respon: "",
      respon_status: true,
      load_store_all: false,
      is_register_sso: false,
    };
  },

  computed: {
    nextButtonText() {
      return this.currentStep === 4 ? "Submit" : "Next";
    },

    isButtonDisabled() {
      return (
        (this.currentStep === 3 && this.unitList < 1) ||
        (this.currentStep === 4 && this.agree_tnc !== true)
      );
    },

    veh_type() {
      return this.vehicle_type ? this.vehicle_type.value : "";
    },
  },

  watch: {
    $route(to, from) {
      this.currentStep = Number(to.query.currentStep) || 0;
    },

    email(value) {
      this.inputFilled = value.length > 0;
    },

    veh_type() {
      if (this.veh_type !== "") {
        this.getVehicleIcon();
        // this.getVehicleType();
      }
    },
  },

  created() {
    if (this.currentStep === 0) {
      localStorage.removeItem("token_register");
      localStorage.removeItem("full_name");
    }

    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.get("token") != null)
      localStorage.setItem("token_register", urlParams.get("token"));

    this.token = localStorage.getItem("token_register");

    if (this.token) {
      const token = decodeURIComponent(this.token.trim());
      try {
        const decodedToken = jwt_decode(token);

        this.email = decodedToken.email;
      } catch (error) {
        console.error("Error decoding token:", error);
      }
    }
  },

  mounted() {
    if (this.token) {
      this.getVehicleType();

      if (!this.is_register_sso) {
        // this.getBranchList();
        this.emailVerification();
      } else {
        if (localStorage.getItem("full_name"))
          this.name = localStorage.getItem("full_name");
      }
    }
  },

  methods: {
    async signUpWithGoogle() {
      const auth = getAuth(firebaseApp); // Get the authentication instance
      const provider = new GoogleAuthProvider();

      provider.setCustomParameters({
        prompt: "select_account",
      });

      try {
        await signInWithPopup(auth, provider).then((res) => {
          this.sso_register(
            res.user.providerData[0].displayName,
            res.user.providerData[0].email,
            res.user.providerData[0].uid
          );
        });
      } catch (error) {
        console.error(error);
      }
    },

    // logout() {
    //   signOut(firebaseAuth)
    //     .then(() => {
    //       console.log("User signed out");
    //       // Lakukan sesuatu setelah logout berhasil
    //     })
    //     .catch((error) => {
    //       console.error("Logout error:", error);
    //     });
    // },

    sso_register(full_name, email, google_id) {
      Axios.post(
        `${api_url}/sso/sso-registration`,
        {
          email: email,
          google_id: google_id,
        },
        {
          headers: {
            "API-Key": `${api_key}`,
          },
        }
      )
        .then((res) => {
          this.respon = res.data.message;
          localStorage.setItem("token_register", res.data.data);
          this.token = localStorage.getItem("token_register");

          if (this.token) {
            const token = decodeURIComponent(this.token.trim());
            try {
              const decodedToken = jwt_decode(token);
              this.email = decodedToken.email;
            } catch (error) {
              console.error("Error decoding token:", error);
            }
          }

          localStorage.setItem("full_name", full_name);
          this.name = full_name;

          this.is_register_sso = true;
          this.$router.push({ query: { currentStep: 2 } });
          this.getVehicleType();
        })
        .catch((err) => {
          this.$toast.error(err.response.data.message, {
            position: "top-right",
            timeout: 3000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: false,
            closeButton: "button",
            icon: true,
            rtl: false,
          });
        });
    },

    verify() {
      this.verify_state = true;
      this.currentStep = 2;
    },

    imeiCheck() {
      return Axios.post(
        `${api_url}/imei/detail-imei`,
        {
          imei: this.imei,
        },
        {
          headers: {
            "API-Key": `${api_key}`,
            Authorization: `Bearer ${this.token}`,
          },
        }
      )
        .then((res) => {
          if (!res.data.status) this.loadAddUnit = false;
          this.imeiCheckSuccess = res.data.status;
          this.imeiCheckMsg = res.data.message;

          if (res.data.data && res.data.data.length > 0) {
            this.imeiDetail = res.data.data[0];
            this.branch = this.imeiDetail.branch_id;
          }
        })
        .catch((err) => {
          this.loadAddUnit = false;
          this.imeiCheckMsg = err.response.data.message
            ? err.response.data.message
            : err.response.data.errors.imei[0];
          this.imeiCheckSuccess = false;
        });
    },

    gsmCheck() {
      return Axios.post(
        `${api_url}/gsmNo/cek-gsm`,
        {
          gsm_number: this.gsm_number,
        },
        {
          headers: {
            "API-Key": `${api_key}`,
            Authorization: `Bearer ${this.token}`,
          },
        }
      )
        .then((res) => {
          if (!res.data.status) this.loadAddUnit = false;
          this.gsmCheckSuccess = res.data.status;
          this.gsmCheckMsg = res.data.message;

          this.gsmDetail = res.data.data;
        })
        .catch((err) => {
          this.loadAddUnit = false;
          this.gsmCheckMsg = err.response.data.message
            ? err.response.data.message
            : err.response.data.errors.gsm_number[0];
          this.gsmCheckSuccess = false;
        });
    },

    submitUnit() {
      this.plateCheckMsg = "";
      this.vehNameCheckMsg = "";
      this.vehTypeCheckMsg = "";
      this.vehIconCheckMsg = "";

      this.loadAddUnit = true;

      this.imeiCheck().then(() => {
        if (this.imeiCheckSuccess) {
          this.gsmCheck().then(() => {
            if (this.gsmCheckSuccess) {
              this.loadAddUnit = false;

              if (!this.plate_no)
                return (this.plateCheckMsg =
                  "please complete the data plate first");

              if (!this.vehicle_name)
                return (this.vehNameCheckMsg =
                  "please complete the vehicle name first");

              if (!this.vehicle_type)
                return (this.vehTypeCheckMsg =
                  "please select the vehicle type first");

              if (!this.vehicle_icon)
                return (this.vehIconCheckMsg =
                  "please select the vehicle icon first");

              // Check the success flag
              this.unitList.push({
                id: this.unitList.length + 1,
                imei: this.imei,
                gps_name: this.imeiDetail.name,
                gps_type: this.imeiDetail.type_gps,
                lt_warranty: this.imeiDetail.lt_warranty,
                gsm_number: this.gsm_number,
                expired_gsm: this.gsmDetail.expired,
                simcard_id: this.gsmDetail.simcard_id,
                plate_no: this.plate_no,
                vehicle_name: this.vehicle_name,
                vehicle_type: this.vehicle_type.value,
                vehicle_icon: this.vehicle_icon.value,
                vehicle_icon_front: this.vehicle_icon,
              });

              this.unitForm = false;
              console.log(this.unitList);
            }
          });
        }
      });
    },

    cancelUnit() {
      this.reset();
      this.unitForm = false;
    },

    delete_unit(index) {
      const list_unit = this.unitList;
      this.unitList = [];

      list_unit.map((e, i) => {
        if (index != i) {
          this.unitList.push(e);
        }
      });
    },

    toggleUnitSidebar() {
      this.unitForm = true;
      this.reset();
    },

    reset() {
      this.imei = "";
      this.gsm_number = "";
      this.plate_no = "";
      this.vehicle_name = "";
      this.vehicle_type = null;
      this.vehicle_icon = null;

      this.imeiCheckMsg =
        "You can see your IMEI number on the unit package box";
      this.gsmCheckMsg = "You can see your GSM number on unit package box";
    },

    handleCredentialResponse(response) {
      console.log("Encoded JWT ID token: " + response.credential);
    },

    togglePassword() {
      this.passwordType =
        this.passwordType === "password" ? "text" : "password";
    },

    toggleConfirmPassword() {
      this.confirmPasswordType =
        this.confirmPasswordType === "password" ? "text" : "password";
    },

    nextStep() {
      if (this.currentStep === 0) {
        this.storeEmail();
      } else if (this.currentStep < 4) {
        this.currentStep++;
        this.$router.push({ query: { currentStep: this.currentStep } });
      } else {
        this.storeAll();
      }
    },

    previousStep() {
      if (this.currentStep > 0) {
        this.currentStep--;
        this.$router.push({ query: { currentStep: this.currentStep } });
      }
    },

    buyGps() {
      window.open("https://www.tokopedia.com/supersprings", "_blank");
    },

    storeEmail() {
      this.loading = true;

      this.$vs.loading({
        background: "#1da1f2",
        color: "#fff",
        container: "#btnStoreEmail",
        scale: 0.45,
      });

      Axios.post(
        `${api_url}/register/email-registration`,
        {
          email: this.email,
          password: this.password,
          confirm_password: this.confirmPassword,
        },
        {
          headers: {
            "API-Key": `${api_key}`,
          },
        }
      )
        .then((res) => {
          this.$vs.loading.close("#btnStoreEmail > .con-vs-loading");
          this.currentStep++;
        })
        .catch((err) => {
          this.$vs.loading.close("#btnStoreEmail > .con-vs-loading");
          this.errorState = true;

          if (err.response && err.response.data) {
            if (err.response.data.status === false) {
              this.errorStat = "email";
              this.errorMessage = err.response.data.message;
            } else if (err.response.data.errors) {
              const errors = err.response.data.errors;

              if (errors.email) {
                this.errorStat = "email";
                this.errorMessage = errors.email[0];
              } else if (errors.password) {
                this.errorStat = "password";
                this.errorMessage = errors.password[0];
              } else if (errors.confirm_password) {
                this.errorStat = "confirm_password";
                this.errorMessage = errors.confirm_password[0];
              }
            } else {
              this.errorMessage = "An error occurred. Please try again later.";
            }
          } else {
            this.errorMessage = "An error occurred. Please try again later.";
          }
        });
    },

    resendStoreEmail() {
      this.loadResend = true;
      Axios.post(
        `${api_url}/register/email-registration`,
        {
          email: this.email,
          password: this.password,
          confirm_password: this.confirmPassword,
        },
        {
          headers: {
            "API-Key": `${api_key}`,
          },
        }
      )
        .then((res) => {
          this.loadResend = false;
        })
        .catch((err) => {
          this.loadResend = false;
        });
    },

    emailVerification() {
      Axios.post(
        `${api_url}/register/email-verification`,
        {},
        {
          headers: {
            "API-Key": `${api_key}`,
            Authorization: `Bearer ${this.token}`,
          },
        }
      )
        .then((res) => {
          this.respon_status = res.data.status;
          this.respon = res.data.message;
        })
        .catch((error) => {
          // console.error(error);
          this.respon_status = error.response.data.status;
          this.respon = error.response.data.message;
        });
    },

    storeAll() {
      this.companyNameCheckMsg = "";
      this.fullNameCheckMsg = "";
      this.phoneNumberCheckMsg = "";
      this.load_store_all = true;

      const postTheBulk = {
        data: [
          {
            user: {
              prename: this.pronounce,
              fullname: this.name,
              branch: this.branch,
              customer_type: this.customerType,
              phone_number_code: this.phone_number_code,
              phone_number: this.phone_number,
              prenameco: this.prenameco ? this.prenameco.value : "",
              company_name: this.company_name,
              refferal: this.referral,
              is_agree: this.agree_tnc ? 1 : 0,
            },
            unit: this.unitList.map((unit) => ({
              imei: unit.imei,
              lt_warranty: unit.lt_warranty,
              gsm_number: unit.gsm_number,
              expired_gsm: unit.expired_gsm,
              simcard_id: unit.simcard_id,
              plate: unit.plate_no,
              vehicle_name: unit.vehicle_name,
              vehicle_type: unit.vehicle_type,
              icon: unit.vehicle_icon,
            })),
          },
        ],
      };

      // const storedToken = localStorage.getItem("token");
      // if (storedToken) {
      Axios.post(`${api_url}/register/store-data`, postTheBulk, {
        headers: {
          "API-Key": `${api_key}`,
          Authorization: `Bearer ${this.token}`,
        },
      })
        .then((res) => {
          window.location.href = `${window.location.origin}/success`;
          // this.$router.push({ name: "success" });
        })
        .catch((error) => {
          if (error.response.data.errors) {
            let errors = error.response.data.errors;

            if (errors.company_name) {
              this.companyNameCheckMsg = errors.company_name[0];
            } else if (errors.fullname) {
              this.fullNameCheckMsg = errors.fullname[0];
            } else if (errors.phone_number) {
              this.phoneNumberCheckMsg = errors.phone_number[0];
            }

            this.load_store_all = false;
          } else {
            this.load_store_all = false;
            alert(error.response.data.message);
          }
        });
      // }
    },

    getVehicleType() {
      Axios.get(`${api_url}/vehicle_type/list`, {
        headers: {
          "API-Key": `${api_key}`,
          Authorization: `Bearer ${this.token}`,
        },
      })
        .then((res) => {
          this.vehicleTypeList = res.data.data;
        })
        .catch((error) => {
          // this.$gf.httpErrorHandler(error);
        });
    },

    getVehicleIcon() {
      Axios.get(`${api_url}/icon/list`, {
        headers: {
          "API-Key": `${api_key}`,
          Authorization: `Bearer ${this.token}`,
        },
        params: {
          veh_type_id: this.veh_type,
        },
      })
        .then((res) => {
          this.vehicleIconList = res.data.data;
        })
        .catch((error) => {
          // this.$gf.httpErrorHandler(error);
        });
    },

    getBranchList() {
      Axios.get(`${api_url}/branch/list`, {
        headers: {
          "API-Key": `${api_key}`,
          Authorization: `Bearer ${this.token}`,
        },
      })
        .then((res) => {
          this.branchList = res.data.data;
        })
        .catch((error) => {
          // this.$gf.httpErrorHand
        });
    },

    submitForm() {
      alert("Register Success!");
    },
  },
};
</script>

<style>
@import url(https://fonts.googleapis.com/css?family=Poppins:100,100italic,200,200italic,300,300italic,regular,italic,500,500italic,600,600italic,700,700italic,800,800italic,900,900italic);

.parent {
  display: flex;
  height: 100vh;
  overflow: hidden !important;
}

.left-content {
  width: 45%;
  background: rgba(29, 161, 242, 1);
  background-image: url("../assets/img/bg-register.svg"),
    url("../assets/img/bg-gradient.png"), url("../assets/img/bg-gradient.png"),
    url("../assets/img/bg-gradient.png");
  background-size: 150px, auto, auto, auto;
  background-repeat: no-repeat;
  background-position: left top, center 300px, -100px 550px, 470px 550px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.right-content {
  width: 55%;
  padding: 40px;
  overflow-y: hidden !important;
}

.separator {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
}

.google-btn {
  width: 100%;
  padding: 12px 16px;
  border-radius: 8px;
  border: 1px solid #dbdade;
  background: #fff;
  box-shadow: 0px 2px 4px 0px rgba(165, 163, 174, 0.3);
}

.separator span:nth-child(2) {
  font-size: 16px;
  font-family: Poppins;
  font-weight: 600;
  text-align: center;
  /* text-transform: capitalize; */
  color: #7a7a7a;
  width: 30%;
}

.line-separator {
  width: 100%;
  height: 1px;
  background: #dbdade;
}

.steps {
  display: flex;
  justify-content: center;
  align-items: center;
}

.steps > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
}

.step {
  width: 24px;
  height: 24px;
  padding: 4px;
  border-radius: 50%;
  /* border: 4px solid rgba(75, 70, 92, 0.16); */
  background: #bababa;
  color: #fff;
  font-weight: 600;
  font-size: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.active-step {
  background: #1da1f2;
  /* border: 4px solid rgba(0, 207, 232, 0.16); */
}

.step-text {
  font-size: 14px;
  font-weight: 600;
  color: #bababa;
}

.active-text {
  color: #1da1f2;
  font-size: 14px;
  font-weight: 600;
}

.line-step {
  background: #bababa;
  width: 60px;
  height: 2px;
}

.active-line {
  background: #1da1f2;
}

.content-wrapper {
  height: 570px;
  overflow-y: auto;
}

.label {
  font-size: 14px;
  font-weight: 600;
}

.input-box {
  border: 1px solid rgba(223, 223, 227, 1);
  border-radius: 8px;
  background: #f8f7fa;
}

.input-box.filled {
  border-color: #1da1f2;
}

.email input,
.password input,
.confirm-password input {
  border: none;
  font-size: 14px;
  background: #f8f7fa;
}

.email input::placeholder,
.password input::placeholder,
.confirm-password input::placeholder {
  font-size: 14px;
}

input:focus-visible {
  outline: none;
}

input:-webkit-autofill {
  background: #f8f7fa !important;
}

.input-note {
  font-size: 12px;
  color: #7a7a7a;
}

.disabled-btn {
  border-radius: 8px;
  border: 1px solid #acacac !important;
  background: #acacac !important;
  padding: 16px;
  color: #fff;
  font-weight: 600;
}

.prev-btn {
  padding: 16px;
  border-radius: 8px;
  border: 1px solid #1da1f2;
  background: none;
  color: #1da1f2;
  font-weight: 600;
}

.next-btn {
  border-radius: 8px;
  border: 1px solid #1da1f2;
  background: linear-gradient(45deg, #1da1f2 0%, #1da1f2 0.01%, #62c1fc 100%);
  padding: 16px;
  color: #fff;
  font-weight: 600;
}

.next-btn:hover {
  background: #157ebe;
}

.prev-btn:hover {
  background: #157ebe;
  color: #fff;
}

.buttons p {
  color: #7a7a7a;
  font-size: 14px;
  font-weight: 500;
}

.buttons a {
  color: #1da1f2 !important;
  font-weight: 600;
  text-decoration: underline !important;
  cursor: pointer !important;
}

.buttons a:hover {
  color: #157ebe !important;
}

.add-unit-btn {
  border-radius: 8px;
  border: 1px solid #dbdade;
  background: #f8f7fa;
  color: #1da1f2;
  font-size: 14px;
  font-weight: 500;
  padding: 22px;
}

.no-unit {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px 0;
  color: #7a7a7a;
}

.unit-form-wrapper {
  padding: 10px 40px;
}

.unit-form-wrapper ul li,
.input-note li {
  margin-left: -15px;
}

.imei input,
.gsm input,
.v-plate input,
.v-name input {
  border: none;
  font-size: 14px;
  background: #f8f7fa;
}

.imei input::placeholder,
.gsm input::placeholder,
.v-plate input::placeholder,
.v-name input::placeholder {
  font-size: 14px;
}

.vehicle {
  display: flex;
  align-items: center;
  gap: 10px;
}

.v-plate {
  width: 40%;
}

.v-name {
  width: 60%;
}

.style-chooser .vs__dropdown-toggle,
.style-chooser .vs__dropdown-menu {
  background: #f8f7fa;
  border: none !important;
  border-radius: 8px;
  color: #1a1a1a;
  padding: 16px;
  font-size: 14px;
}

.style-chooser .vs__search::placeholder {
  font-size: 14px;
  color: #7a7a7a;
}

.style-chooser .vs__open-indicator {
  fill: #202020;
}

.style-chooser .vs__clear {
  display: none;
}

.total-unit {
  display: flex;
  justify-content: space-between;
  padding: 8px 16px;
  background: #f8f7fa;
  color: #4a4a4a;
  font-weight: 600;
  font-size: 16px;
}

.unit-card-wrapper {
  padding: 20px;
  background: #f8f7fa;
  overflow-y: scroll;
  height: 400px;
}

.unit-card {
  padding: 16px;
  border-radius: 8px;
  border: 1px solid #1da1f2;
  background: #fff;
  box-shadow: 0px 4px 16px 0px rgba(165, 163, 174, 0.45);
}

.unit-card-top {
  border-bottom: 1px solid #dbdade;
  padding-bottom: 8px;
}

.unit-card-top .unit-item-left {
  color: #000;
  font-weight: 600;
  font-size: 16px;
}

.unit-card-top .unit-item-right {
  padding: 2px 12px;
  border-radius: 13px;
  background: rgba(0, 207, 232, 0.16);
  font-weight: 600;
  font-size: 12px;
  color: #1da1f2;
}

.unit-card-top .unit-item-center {
  padding: 2px 12px;
  border-radius: 13px;
  background: rgba(0, 207, 232, 0.16);
  font-weight: 600;
  font-size: 12px;
  color: #1da1f2;
}

.unit-card-bottom {
  padding-top: 8px;
}

.unit-card-bottom .unit-item-left span {
  display: block;
}

.unit-card-bottom .unit-item-right span {
  display: block;
  text-align: end;
}

.unit-card-bottom .unit-item-center span {
  display: block;
  text-align: start;
}

.unit-card-bottom .unit-item-left span:first-child,
.unit-card-bottom .unit-item-right span:first-child,
.unit-card-bottom .unit-item-center span:first-child {
  color: #7a7a7a;
  font-size: 12px;
  font-weight: 500;
}

.unit-card-bottom .unit-item-left span:nth-child(2),
.unit-card-bottom .unit-item-right span:nth-child(2),
.unit-card-bottom .unit-item-center span:nth-child(2) {
  color: #1a1a1a;
  font-size: 12px;
  font-weight: 600;
}

.unit-form-wrapper .btn-cancel {
  width: 40%;
  padding: 16px;
  border-radius: 8px;
  border: 1px solid #1da1f2;
  background: none;
  color: #1da1f2;
  font-weight: 600;
}
.unit-form-wrapper .btn-submit-unit {
  width: 60%;
  padding: 16px;
  border-radius: 8px;
  border: 1px solid #1da1f2;
  background: linear-gradient(45deg, #1da1f2 0%, #1da1f2 0.01%, #62c1fc 100%);
  color: #fff;
  font-weight: 600;
}

.name input,
.phone input,
.referral input {
  border: none;
  font-size: 14px;
  background: #f8f7fa;
}

.name input::placeholder,
.phone input::placeholder,
.referral input::placeholder {
  font-size: 14px;
}

.customer-type label {
  font-size: 14px;
  color: #1a1a1a;
  font-weight: 400;
}

/* .customer-type .indiv-radio,
.customer-type .comp-radio {
  cursor: pointer !important;
} */

.tnc {
  color: #7a7a7a;
  font-size: 14px;
}

.verify-email h1 {
  color: #1a1a1a;
  font-size: 32px;
  font-family: Poppins;
  font-weight: 600;
  text-transform: capitalize;
}

.verify-email p {
  color: #7a7a7a;
  font-size: 16px;
}

.verify-email p span {
  color: #1a1a1a;
  font-weight: 600;
}

.verify-email ul li {
  list-style: none;
}

.verify-email ul li::before {
  content: "\2022";
  color: #1da1f2;
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}

.verify-email ul li a {
  color: #1da1f2;
}

.verify-success h1 {
  color: #1a1a1a;
  font-size: 32px;
  font-family: Poppins;
  font-weight: 600;
  text-transform: capitalize;
}

.verify-success p {
  color: #7a7a7a;
  font-size: 16px;
}

/* Gaya tombol */
.google-signup {
  border: 1px solid #1da1f2;
  border-radius: 8px;
  background: none;
  font-size: 16px;
  font-weight: 600;
  color: #1da1f2;
}

/* Hover effect */
.google-signup:hover {
  background: #157ebe;
  color: #fff;
}

/* Icon */
.google-icon {
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
  width: 24px;
  height: 24px;
  background-image: url("@/assets/google-icon.png");
  background-size: cover;
}

/* Text */
.button-text {
  display: inline-block;
  vertical-align: middle;
}
</style>
