import Vue from "vue";
import App from "./App.vue";
import router from "./router";

import VueI18n from "vue-i18n";

Vue.use(VueI18n);

const i18n = new VueI18n({
  locale: "en",
  fallbackLocale: "en",
  messages: {
    en: require("./locales/en.json"),
    id: require("./locales/id.json"),
  },
});

Vue.config.productionTip = false;

// Bootstrap
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);

// Vuesax Component Framework
import Vuesax from "vuesax";
import "material-icons/iconfont/material-icons.css"; //Material Icons
import "vuesax/dist/vuesax.css"; // Vuesax
Vue.use(Vuesax);

// Vue Select
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
Vue.component("v-select", vSelect);

// Vue Toastification
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";

Vue.use(Toast, {
  transition: "Vue-Toastification__bounce",
  maxToasts: 20,
  newestOnTop: true,
});

import VueCarousel from "vue-carousel";
Vue.use(VueCarousel);

import { firebaseConfig } from "./firebaseConfig"; // Import your Firebase configuration
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

// Initialize Firebase with your configuration
const firebaseApp = initializeApp(firebaseConfig);
const firebaseAuth = getAuth(firebaseApp);
export { firebaseApp, firebaseAuth };

new Vue({
  router,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
