<template>
  <div class="parent login-wrapper">
    <div
      class="left-content d-flex flex-column justify-content-center align-items-center gap-3"
    >
      <img
        src="../assets/img/LOGO_GPS_id_with_SUPERSPRING.png"
        class="my-3"
        width="300"
        alt=""
      />
      <h1 class="text-center my-3">
        {{ $t("banner_caption") }}
      </h1>
      <Banner />
      <div class="link-to-mobile my-3">
        <a @click="showPlayStore">
          <img src="../assets/img/play_store.svg" alt="" />
        </a>
        <a @click="showAppStore">
          <img src="../assets/img/app_store.svg" alt="" />
        </a>
        <a @click="showHuaweiStore">
          <img src="../assets/img/huawei_store.svg" alt="" />
        </a>
      </div>
    </div>

    <div class="right-content p-5">
      <div class="custom-select-wrapper">
        <div class="custom-select" @click="toggleDropdown">
          <img
            v-if="selectedLangId === 'en'"
            src="../assets/img/us.png"
            width="30"
            alt=""
          />
          <img v-else src="../assets/img/indonesia.png" width="28" alt="" />
          <span style="color: #157ebe">▼</span>
        </div>

        <ul v-if="showDropdown" class="dropdown-options">
          <li @click="selectLanguage('en')">
            <img src="../assets/img/us.png" width="30" alt="" />
            English (US)
          </li>
          <li @click="selectLanguage('id')">
            <img src="../assets/img/indonesia.png" width="28" alt="" />
            Indonesia
          </li>
        </ul>
      </div>
      <!-- <div class="language-wrapper">
        <div class="d-flex align-items-center gap-2 selected-lang">
          <div v-html="$t('selected_lang')"></div>
          <span>▼</span>
        </div>
        <select
          name="languages"
          id="languages"
          v-model="selectedLangId"
          @change="changeLanguage"
        >
          <option value="en" selected>
            <img src="../assets/img/huawei_store.svg" alt="" />
            &#127482;&#127480; English (US)
          </option>
          <option value="id">&#127470;&#127465; Indonesia</option>
        </select>
      </div> -->

      <div class="logo-mobile text-center mb-5">
        <img src="../assets/img/logo-ss.svg" width="240" alt="" />
      </div>
      <h1>{{ $t("title") }}</h1>
      <h2>{{ $t("subtitle") }}</h2>

      <div class="form">
        <div class="username d-flex flex-column my-4">
          <span class="label mb-2">Username</span>
          <div :class="['input-box', 'p-3', { filled: inputFilled }]">
            <input
              class="w-100"
              v-model="username"
              type="text"
              :placeholder="$t('ph_username')"
              @keydown.enter="handleKeyDown"
            />
          </div>
        </div>
        <div class="password d-flex flex-column my-4">
          <span class="label mb-2">{{ $t("password") }}</span>
          <div
            :class="[
              'input-box',
              'p-3',
              'd-flex',
              'justify-content-between',
              'align-items-center',
              { filled: inputFilledPass },
            ]"
          >
            <input
              class="w-100"
              v-model="password"
              :type="passwordType"
              :placeholder="$t('ph_password')"
              @keydown.enter="handleKeyDown"
            />

            <div class="toggle-password" @click="togglePassword">
              <img
                v-if="passwordType === 'text'"
                src="../assets/img/eye-open.svg"
                alt=""
              />
              <img v-else src="../assets/img/eye-closed.svg" alt="" />
            </div>
          </div>
        </div>
        <div class="links d-flex justify-content-end">
          <!-- <vs-checkbox name="checkbox" v-model="saveLogin" color="#1DA1F2">
            Remember Me
          </vs-checkbox> -->

          <a @click="goToForgotPassword">{{ $t("forgot_password") }}</a>
        </div>
        <div class="buttons d-flex flex-column gap-3 my-3">
          <button
            id="btnLogin"
            class="btn-login p-3 w-100 vs-con-loading__container"
            @click="doLogin"
          >
            {{ $t("button_login") }}
          </button>

          <hr />

          <div class="row">
            <div class="col-lg-6 col-12 mb-2">
              <button
                id="btnDemo"
                class="btn-demo p-3 w-100 vs-con-loading__container"
                @click="doDemo"
              >
                {{ $t("button_demo") }}
              </button>
            </div>
            <div class="col-lg-6 col-12">
              <button
                class="google-signin p-3 w-100 vs-con-loading__container"
                @click="signInWithGoogle"
              >
                <img src="../assets/google-icon.png" width="20" alt="google" />
                <span id="btnGoogle" class="button-text">
                  {{ $t("button_google") }}
                </span>
              </button>
            </div>
          </div>
        </div>

        <div class="footer text-center">
          <p>
            {{ $t("no_account") }}
            <a @click="goToRegister">{{ $t("no_account_url") }}</a>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { url_v1, url_v2 } from "../config.js";
import { portal_url, mobile_url } from "../api.js";
import { Base64 } from "js-base64";
import Banner from "./Banner.vue";
import Axios from "axios";

import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";

import { firebaseApp } from "@/main";

export default {
  name: "Login",

  components: {
    Banner,
  },

  data() {
    return {
      // url_v1: "https://gps.id",
      // url_v2: "http://192.168.97.123:8080",
      // portal_url: "https://dev-portal.gps.id/backend",
      // base_url: "https://dev-seen.gps.id/backend",

      username: "",
      password: "",
      passwordType: "password",
      data: [],
      getError: "Processing to login",

      saveLogin: false,

      loading: false,
      inputFilled: false,
      inputFilledPass: false,
      showDropdown: false,
      selectedLangId: "en",
    };
  },

  mounted() {
    const screenWidth = window.innerWidth;

    if (screenWidth < 535) {
      window.location.href = mobile_url;
    }

    localStorage.setItem("apiToken", "");
    localStorage.setItem("username", "");
  },

  // computed() {
  //   selectedLang() {
  //     if (this.selectedLangId == 'id') {
  //       return 'Indonesia &#127470;&#127465;'
  //     } else {

  //     }
  //   }
  // },

  watch: {
    username(value) {
      this.inputFilled = value.length > 0;
    },

    password(value) {
      this.inputFilledPass = value.length > 0;
    },

    // selectedLangId(newLang) {
    //   console.log("selectedLang changed:", newLang);
    // },
  },

  methods: {
    toggleDropdown() {
      this.showDropdown = !this.showDropdown;
    },

    selectLanguage(lang) {
      this.selectedLangId = lang;
      this.$i18n.locale = this.selectedLangId;
      this.showDropdown = false;
      // Perform any other necessary actions upon selecting a language
    },

    changeLanguage() {
      this.$i18n.locale = this.selectedLangId;
    },

    async signInWithGoogle() {
      const auth = getAuth(firebaseApp); // Get the authentication instance
      const provider = new GoogleAuthProvider();

      provider.setCustomParameters({
        prompt: "select_account",
      });

      try {
        await signInWithPopup(auth, provider).then((res) => {
          this.sso_login(
            res.user.providerData[0].email,
            res.user.providerData[0].uid
          );
        });
      } catch (error) {
        console.error(error);
      }
    },

    sso_login(email, google_id) {
      this.loading = true;

      this.$vs.loading({
        background: "#1da1f2",
        color: "#fff",
        container: "#btnGoogle",
        scale: 0.45,
      });

      Axios.post(`${portal_url}/api/sso_login`, {
        email: email,
        google_id: google_id,
      })
        .then((res) => {
          // console.log(res.data.message);
          if (res.data.status === true) {
            this.data = res.data.message.data;
            this.getProfile();
          }
        })
        .catch((error) => {
          if (typeof error.response.data.message !== "undefined") {
            this.getError = error.response.data.message;
          }
          this.$vs.loading.close(`#btnGoogle > .con-vs-loading`);

          this.$toast.error(this.getError, {
            position: "top-right",
            timeout: 3000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: false,
            closeButton: "button",
            icon: true,
            rtl: false,
          });
        });
    },

    handleSaveLogin() {
      if (this.saveLogin) {
        // localStorage.setItem("username", this.username);
        localStorage.setItem("saveLogin", "true");
      } else {
        // localStorage.removeItem("username");
        localStorage.removeItem("saveLogin");
      }
    },

    handleKeyDown(e) {
      if (e.key === "Enter" || e.key === "EnterKey" || e.key === "Return") {
        this.doLogin();
      }
    },

    doLogin() {
      this.loading = true;

      this.$vs.loading({
        background:
          "linear-gradient(45deg, #003575 0%, #155aaf 0.01%, #328fff 100%)",
        color: "#fff",
        container: "#btnLogin",
        scale: 0.45,
      });

      Axios.post(`${portal_url}/api/single_login`, {
        username: this.username,
        password: this.password,
      })
        .then((res) => {
          if (res.data.platform === "gps_id_v1") {
            this.loading = false;

            // this.$vs.notify({
            //   title: "Thank You",
            //   text: "Login Success",
            //   color: "success",
            //   position: "top-right",
            // });

            this.$toast.success("Login Success", {
              position: "top-right",
              timeout: 3000,
              closeOnClick: true,
              pauseOnFocusLoss: true,
              pauseOnHover: true,
              draggable: true,
              draggablePercent: 0.6,
              showCloseButtonOnHover: false,
              hideProgressBar: false,
              closeButton: "button",
              icon: true,
              rtl: false,
            });

            this.$vs.loading.close(`#btnLogin > .con-vs-loading`);

            setTimeout(() => {
              this.data = JSON.stringify({
                RequestUserName: this.username,
                RequestPassword: this.password,
                RequestLanguage: "en",
              });

              window.location = `${url_v1}/log.php?apiKey=${Base64.btoa(
                this.data
              )}`;
            }, 2000);
          } else {
            if (res.data.status === true) {
              this.data = res.data.message.data;
              this.getProfile();
            }
          }
        })
        .catch((error) => {
          if (typeof error.response.data.message !== "undefined") {
            this.getError = error.response.data.message;
          }
          this.$vs.loading.close(`#btnLogin > .con-vs-loading`);
          // this.$vs.notify({
          //   title: "Sorry",
          //   text: "Login Failed",
          //   color: "danger",
          //   position: "top-right",
          // });
          this.$toast.error("Invalid Credentials", {
            position: "top-right",
            timeout: 3000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: false,
            closeButton: "button",
            icon: true,
            rtl: false,
          });
        });
    },

    getProfile() {
      Axios.get(`${portal_url}/api/profile`, {
        headers: {
          Authorization: `Bearer ${this.data.token}`,
        },
      })
        .then((res) => {
          const myApp = res.data.message.data;
          if (myApp.apps.apps.indexOf(2) !== -1) {
            this.$toast.success("Login Success", {
              position: "top-right",
              timeout: 3000,
              closeOnClick: true,
              pauseOnFocusLoss: true,
              pauseOnHover: true,
              draggable: true,
              draggablePercent: 0.6,
              showCloseButtonOnHover: false,
              hideProgressBar: false,
              closeButton: "button",
              icon: true,
              rtl: false,
            });
            // this.$vs.notify({
            //   title: "Thank You",
            //   text: "Login Success",
            //   color: "success",
            //   position: "top-right",
            // });
            setTimeout(() => {
              window.location.href = `${url_v2}/login/${this.data.token}?isGenerate=${this.data.isGenerate}&languagesId=${this.selectedLangId}`;
            }, 2000);
          } else {
            this.$vs.notify({
              title: "Sorry",
              text: "Don't have access to tracking system",
              color: "success",
              position: "top-right",
            });
          }
        })
        .catch((error) => {
          if (typeof error.response.data.message !== "undefined") {
            this.getError = error.response.data.message;
          }
          // this.$vs.notify({
          //   title: "Sorry",
          //   text: "Login Failed",
          //   color: "danger",
          //   position: "top-right",
          // });
          this.$toast.error("Invalid Credentials", {
            position: "top-right",
            timeout: 3000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: false,
            closeButton: "button",
            icon: true,
            rtl: false,
          });
        });
    },

    doDemo() {
      this.loading = true;

      this.$vs.loading({
        background: "#fff",
        color: "#1da1f2",
        container: "#btnDemo",
        scale: 0.45,
      });

      Axios.post(`${portal_url}/api/demo_login`, {
        apikey: "1b1f5d489651898e2519736efacc8ac2",
      })
        .then((res) => {
          this.loading = false;
          this.$vs.loading.close(`#btnLogin > .con-vs-loading`);
          // this.$vs.notify({
          //   title: "Thank You",
          //   text: "Login Demo Success",
          //   color: "success",
          //   position: "top-right",
          // });
          this.$toast.success("Login Demo Success", {
            position: "top-right",
            timeout: 3000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: false,
            closeButton: "button",
            icon: true,
            rtl: false,
          });
          if (res.data.status === true) {
            setTimeout(() => {
              this.data = res.data.message.data;
              window.location.href = `${url_v2}/login/${this.data.token}?languagesId=${this.selectedLangId}`;
            }, 2000);
          }
        })
        .catch((error) => {
          if (typeof error.response.data.message !== "undefined") {
            this.getError = error.response.data.message;
          }
          this.$vs.notify({
            title: "Sorry",
            text: "Login Demo Failed",
            color: "danger",
            position: "top-right",
          });
        });
    },

    showPlayStore() {
      window.open(
        "https://play.google.com/store/apps/details?id=com.superspring.gpsid",
        "_blank"
      );
    },

    showAppStore() {
      window.open(
        "https://apps.apple.com/id/app/gps-id-dari-super-spring/id1119572414",
        "_blank"
      );
    },

    showHuaweiStore() {
      window.open("https://appgallery.huawei.com/app/C108220087", "_blank");
    },

    goToForgotPassword() {
      window.open("https://forgot.gps.id/password", "_blank");
    },

    goToRegister() {
      this.$router.push({ name: "register" });
    },

    togglePassword() {
      if (this.passwordType === "password") {
        this.passwordType = "text";
      } else this.passwordType = "password";
    },
  },
};
</script>

<style scoped>
.parent {
  display: flex;
  height: 100vh;
}

.left-content {
  width: 60%;
  /* background: #f8f7fa; */
  /* background: url("../assets/img/login-bg.svg"), #f1f1f5; */
  background: #f1f1f5;
  background-repeat: no-repeat;
  height: 100%;
}

.left-content h1 {
  color: #0c5179;
  font-size: 24px;
  font-weight: 700;
  text-transform: capitalize;
  width: 70%;
}

.left-content .banner {
  background: transparent;
  box-shadow: -1px 7px 12px 4px rgba(112, 112, 112, 0.75);
  -webkit-box-shadow: -1px 7px 12px 4px rgba(112, 112, 112, 0.75);
  -moz-box-shadow: -1px 7px 12px 4px rgba(112, 112, 112, 0.75);
}

.left-content .link-to-mobile a {
  margin-inline: 8px;
  cursor: pointer;
}

.left-content .link-to-mobile a img {
  width: 150px;
}

.right-content {
  width: 40%;
  background: #fff;
}

.logo-mobile {
  display: none;
}

.right-content h1 {
  font-size: 25px;
  font-weight: 600;
  text-transform: capitalize;
}

.right-content h2 {
  font-size: 15px;
  font-weight: 500;
  color: #7a7a7a;
}

.form .label {
  font-size: 15px;
  font-weight: 600;
}

.form .input-box {
  border: 1px solid rgba(223, 223, 227, 1);
  border-radius: 8px;
  background: #f8f7fa;
}

.form .input-box.filled {
  border-color: #003575;
}

.form .username input,
.form .password input {
  border: none;
  font-size: 15px;
  background: #f8f7fa;
}

.form .username input::placeholder,
.form .password input::placeholder {
  font-size: 15px;
}

.form input:focus-visible {
  outline: none;
}

.form input:-webkit-autofill {
  background: #f8f7fa !important;
}

.links {
  font-size: 15px;
}

.links a {
  font-style: italic;
  font-size: 15px;
  color: #4a4a4a;
  text-decoration: underline !important;
  cursor: pointer;
}

.links a:hover {
  color: #157ebe !important;
}

.btn-login {
  /* border: 1px solid #003575; */
  /* background: linear-gradient(45deg, #1da1f2 0%, #1da1f2 0.01%, #62c1fc 100%); */
  border: 1px solid;
  border-radius: 8px;
  border-image: linear-gradient(45deg, #003575 0%, #003575 0.01%, #328fff 100%);
  background: linear-gradient(45deg, #003575 0%, #003575 0.01%, #328fff 100%);
  font-size: 17px;
  font-weight: 600;
  color: #fff;
}

.btn-login:hover {
  /* background: #157ebe; */
  background: linear-gradient(45deg, #003575 0%, #155aaf 0.01%, #328fff 100%);
}

.btn-demo {
  border: 1px solid #003575;
  border-radius: 8px;
  background: none;
  font-size: 15px;
  font-weight: 600;
  color: #003575;
}

.btn-demo:hover {
  background: #1861bb;
  /* background: #1da1f2; */
  color: #fff;
}

.footer p {
  color: #7a7a7a;
  font-size: 14px;
  font-weight: 500;
}

.footer a {
  color: #003575 !important;
  font-weight: 600;
  text-decoration: underline !important;
  cursor: pointer !important;
}

.footer a:hover {
  color: #1861bb !important;
}

/* Gaya tombol */

.google-signin img {
  margin-right: 10px;
}

.google-signin {
  border: 1px solid #003575;
  border-radius: 8px;
  background: none;
  font-size: 15px;
  font-weight: 600;
  color: #003575;
}

/* Hover effect */
.google-signin:hover {
  background: #1861bb;
  color: #fff;
}

/* Icon */
.google-icon {
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
  width: 24px;
  height: 24px;
  background-image: url("@/assets/google-icon.png");
  background-size: cover;
}

/* Text */
.button-text {
  display: inline-block;
  vertical-align: middle;
}

/* .language-wrapper {
  position: relative;
  display: flex;
  justify-content: end;
  cursor: pointer !important;
}

.selected-lang {
  position: absolute;
  cursor: pointer !important;
}

.selected-lang > div {
  font-size: 24px;
}

.selected-lang > span {
  font-size: 16px;
  color: #157ebe;
}

#languages {
  opacity: 0;
  cursor: pointer !important;
  z-index: 999999;
  height: 30px;
} */
.custom-select-wrapper {
  position: relative;
  display: flex;
  justify-content: end;
}
.custom-select {
  display: inline-block;
  padding: 8px;
  /* border: 1px solid #ccc; */
  cursor: pointer;
}
.custom-select:hover {
  background-color: #f1f1f1;
}
.dropdown-options {
  list-style-type: none;
  padding: 0;
  margin: 0;
  border: 1px solid #ccc;
  cursor: pointer;
  position: absolute;
  background: rgb(249, 249, 249);
}
.dropdown-options li {
  padding: 8px;
}
.dropdown-options li:hover {
  background-color: #f1f1f1;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .login-wrapper {
    display: flex;
    flex-direction: column;
  }

  .left-content {
    display: none !important;
  }

  .right-content {
    width: 100%;
  }

  .logo-mobile {
    display: block;
  }

  .password .input-box .toggle-password {
    margin-left: -30px;
  }
}
</style>
