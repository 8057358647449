import Vue from "vue";
import Router from "vue-router";
Vue.use(Router);

import Login from "./components/Login.vue";
import Register from "./components/Register.vue";
import Success from "./components/SuccessRegister.vue";
import Logout from "./components/Logout.vue";
import VerificatioMobile from "./components/VerificationMobile.vue";
import ConfirmDeleteAccount from "./components/ConfirmDeleteAccount.vue";
import EmailVerification from "./components/EmailVerification.vue";
import ConfirmEditAccount from "./components/ConfirmEditAccount.vue";

export default new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      name: "login",
      component: Login,
    },
    {
      path: "/register",
      name: "register",
      component: Register,
      props: (route) => ({ currentStep: route.query.currentStep || 0 }),
    },
    {
      path: "/success",
      name: "success",
      component: Success,
    },
    {
      path: "/logout/:token",
      name: "logout",
      component: Logout,
    },
    {
      path: "/verification-mobile",
      name: "verification-mobile",
      component: VerificatioMobile,
    },
    {
      path: "/confirmation-delete-account/:token",
      name: "confirmation-delete-account",
      component: ConfirmDeleteAccount,
    },
    {
      path: "/email-verification/:token",
      name: "email-verification",
      component: EmailVerification,
    },
    {
      path: "/confirmation-edit-account/:email_verification",
      name: "confirmation-edit-account",
      component: ConfirmEditAccount,
    },
  ],
});
