<template>
  <div class="parentx">
    <b-carousel
      id="banner-carousel"
      :interval="3000"
      indicators
      style="text-shadow: 1px 1px 2px #333; border-radius: 10px"
      @sliding-start="onSlideStart"
      @sliding-end="onSlideEnd"
    >
      <div class="img-wrapper" v-for="item in data" :key="item.id">
        <a
          :href="item.url_link"
          :target="item.url_link != null && item.url_link != '' ? 'blank' : ''"
        >
          <b-carousel-slide :img-src="item.file"></b-carousel-slide>
        </a>
      </div>
    </b-carousel>
  </div>
</template>

<script>
import Axios from "axios";
import { BCarousel } from "bootstrap-vue";

import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
// optional style for arrows & dots
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";

export default {
  name: "Banner",

  components: {
    VueSlickCarousel,
    BCarousel,
  },

  data() {
    return {
      data: [],
      portal_url: "https://portal.gps.id/backend",
      sliding: null,

      settings: {
        dots: true,
        dotsClass: "slick-dots custom-dot-class",
        edgeFriction: 0.35,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    };
  },

  //   created() {
  //     this.getBanner();
  //   },

  mounted() {
    this.getBanner();
  },

  methods: {
    getBanner() {
      Axios.get(`${this.portal_url}/api/banner`).then((res) => {
        this.data = res.data.message.data;
      });
    },

    onSlideStart(slide) {
      this.sliding = true;
    },
    onSlideEnd(slide) {
      this.sliding = false;
    },
  },
};
</script>

<style>
/* #banner-carousel {
  box-shadow: -1px 7px 12px 4px rgba(112, 112, 112, 0.75);
  -webkit-box-shadow: -1px 7px 12px 4px rgba(112, 112, 112, 0.75);
  -moz-box-shadow: -1px 7px 12px 4px rgba(112, 112, 112, 0.75);
} */

.img-wrapper {
  /* border-radius: 10px; */
  width: 700px !important;
}

.img-wrapper img {
  border-radius: 10px;
}

.carousel-indicators li {
  background-color: #dbdade;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  margin-inline: 4px;

  box-shadow: -1px 2px 12px 2px rgba(112, 112, 112, 0.48);
  -webkit-box-shadow: -1px 2px 12px 2px rgba(112, 112, 112, 0.48);
  -moz-box-shadow: -1px 2px 12px 2px rgba(112, 112, 112, 0.48);
}

.carousel-indicators li::marker {
  content: " " !important;
}

.carousel-indicators li.active {
  background-color: #1da1f2;
}

/* .carousel-control-prev-icon {
    background-image: url(data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23fff%27%3e%3cpath d=%27M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z%27/%3e%3c/svg%3e);
} */
</style>
