<template>
  <div class="parent d-flex justify-content-center align-items-center">
    <div class="verify-success text-center">
      <h1>{{ respon }}</h1>
      <br />
      <img src="../assets/img/verify-success.png" alt="" width="400" />
      <br />
      <!-- <p>
        {{ respon }}
      </p> -->
    </div>
  </div>
</template>

<script>
import { api_key, api_url } from "../api.js";
import Axios from "axios";
import jwt_decode from "jwt-decode";

export default {
  data() {
    return {
      token: "",
      email: "",
      respon: "",
    };
  },

  created() {
    const urlParams = new URLSearchParams(window.location.search);
    this.token = urlParams.get("token");

    if (this.token) {
      const token = decodeURIComponent(this.token.trim());
      localStorage.setItem("token", token);

      try {
        const decodedToken = jwt_decode(token);
        this.email = decodedToken.email;
      } catch (error) {
        console.error("Error decoding token:", error);
      }
    }
  },

  mounted() {
    if (this.token) {
      this.emailVerification();
    }
  },

  methods: {
    emailVerification() {
      Axios.post(
        `${api_url}/register/email-verification`,
        {},
        {
          headers: {
            "API-Key": `${api_key}`,
            Authorization: `Bearer ${this.token}`,
          },
        }
      )
        .then((res) => {
          this.respon = res.data.message;
        })
        .catch((error) => {
          this.respon = error.response.data.message;
          console.error(error);
        });
    },
  },
};
</script>

<style scoped>
.verify-success h1 {
  color: #1a1a1a;
  font-size: 46px;
  font-family: Poppins;
  font-weight: 600;
  text-transform: capitalize;
}

.verify-success p {
  color: #7a7a7a;
  font-size: 20px;
}
</style>
