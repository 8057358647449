// env.js
const env = {
  API_KEY: "AIzaSyCZqo3ayTqF_UeKwaU_4SPVNyE0E31ciNU",
  AUTH_DOMAIN: "superspring-gcs.firebaseapp.com",
  PROJECT_ID: "superspring-gcs",
  STORAGE_BUCKET: "superspring-gcs.appspot.com",
  MESSAGING_SENDER_ID: "598992388497",
  APP_ID: "1:598992388497:web:7e4bb53a6d7d338d709a30",
  MEASUREMENT_ID: "G-1CFWMGNXCP",
};

export default env;
