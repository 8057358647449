<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
import Login from "./components/Login.vue";

export default {
  name: "App",
  components: {
    Login,
  },
};
</script>

<style>
@import url(https://fonts.googleapis.com/css?family=Poppins:100,100italic,200,200italic,300,300italic,regular,italic,500,500italic,600,600italic,700,700italic,800,800italic,900,900italic);

@font-face {
  font-family: "Arboria-Medium";
  src: url("~@/assets/fonts/Arboria-Medium.ttf") format("truetype");
  font-style: normal;
}

#app {
  font-family: "Arboria-Medium";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0 !important;
  padding: 0 !important;
  box-sizing: border-box !important;
}
</style>
