<template>
  <div class="parent d-flex justify-content-center align-items-center">
    <div class="verify-success text-center">
      <h1>{{ respon }}</h1>
      <br />
      <img src="../assets/img/verify-success.png" alt="" width="400" />
      <br />
      <!-- <p>
        {{ respon }}
      </p> -->
    </div>
  </div>
</template>

<script>
import { portal_url } from "../api.js";
import Axios from "axios";

export default {
  data() {
    return {
      token: "",
      respon: "",
    };
  },

  created() {
    this.token = this.$route.params.token;
  },

  mounted() {
    if (this.token) {
      this.deleteAccount();
    }
  },

  methods: {
    deleteAccount() {
      Axios.post(`${portal_url}/api/confirm_delete_account`, {
        email_verification: this.token,
      })
        .then((res) => {
          this.respon = res.data.message;
        })
        .catch((error) => {
          this.respon = error.response.data.message;
        });
    },
  },
};
</script>

<style scoped>
.verify-success h1 {
  color: #1a1a1a;
  font-size: 46px;
  font-family: Poppins;
  font-weight: 600;
  text-transform: capitalize;
}

.verify-success p {
  color: #7a7a7a;
  font-size: 20px;
}
</style>
