<template>
  <div class="banner-register-wrapper">
    <div class="slide-container">
      <div class="slide-item overlay-fade">
        <img src="../assets/img/slide-img/tracking-slide.png" alt="Tracking" />
        <div class="slide-title">Tracking</div>
        <div class="slide-caption">
          Dengan fitur tracking, kamu dapat mengawasi dan mengelola kendaraan mu
          dengan lebih baik. Informasi lokasi real-time, riwayat perjalanan, dan
          fitur pendukung lainnya membantu meningkatkan efisiensi, keamanan, dan
          kinerja keseluruhan armada kendaraan.
        </div>
      </div>

      <div class="slide-item overlay-fade">
        <img
          src="../assets/img/slide-img/live-camera-slide.png"
          alt="Live Camera"
        />
        <div class="slide-title">Live Camera</div>
        <div class="slide-caption">
          Cammy X40 GPS.ID juga terintegrasi dengan teknologi pelacakan GPS yang
          memungkinkan kamu untuk melacak dan memantau lokasi kendaraan secara
          real-time. Perekaman video dapat mencakup pandangan depan atau
          pandangan dalam kabin kendaraan.
        </div>
      </div>

      <div class="slide-item overlay-fade">
        <img
          src="../assets/img/slide-img/track-replay-slide.png"
          alt="Track Replay"
        />
        <div class="slide-title">Track Replay</div>
        <div class="slide-caption">
          Menyimpan data perjalanan kendaraan, termasuk informasi lokasi, waktu,
          kecepatan, dan parameter lainnya. Kamu dapat memutar kembali rekaman,
          melihat kebiasaan mengemudi, mengidentifikasi pola perjalanan, dan
          mengoptimalkan efisiensi operasional.
        </div>
      </div>

      <div class="slide-item overlay-fade">
        <img
          src="../assets/img/slide-img/notif-slide.png"
          alt="All In One Notifications"
        />
        <div class="slide-title">All In One Notifications</div>
        <div class="slide-caption">
          Kamu akan mendapatkan notifikasi secara real-time. Ini memungkinkan
          kamu untuk mengelola armada kendaraan dengan lebih efisien,
          meningkatkan keamanan, dan memantau kinerja kendaraan.
        </div>
      </div>

      <div class="slide-item overlay-fade">
        <img
          src="../assets/img/slide-img/report-center-slide.png"
          alt="Report Center"
        />
        <div class="slide-title">Report Center</div>
        <div class="slide-caption">
          Menyediakan berbagai jenis laporan yang dapat ditampilkan.Fitur yang
          memungkinkan kamu untuk menghasilkan laporan terkait data pelacakan
          kendaraan. Fitur ini dirancang untuk memberikan informasi yang
          terperinci tentang operasional armada kendaraan.
        </div>
      </div>

      <div class="slide-indicators mt-3">
        <span class="dot"></span>
        <span class="dot"></span>
        <span class="dot"></span>
        <span class="dot"></span>
        <span class="dot"></span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BannerRegister",

  data() {
    return {
      slideIndex: 0,
    };
  },

  mounted() {
    this.showSlides();
  },

  methods: {
    showSlides() {
      let i;
      let slides = document.querySelectorAll(".slide-item");
      let dots = document.querySelectorAll(".dot");
      for (i = 0; i < slides.length; i++) {
        slides[i].style.display = "none";
      }
      this.slideIndex++;

      if (this.slideIndex > slides.length) {
        this.slideIndex = 1;
      }

      for (i = 0; i < dots.length; i++) {
        dots[i].className = dots[i].className.replace(" active", "");
        // dots[i].classList.remove("active");
      }

      slides[this.slideIndex - 1].style.display = "flex";
      dots[this.slideIndex - 1].className += " active";
      //   dots[this.slideIndex - 1].classList.add("active");

      slides.forEach((slide) => {
        slide.addEventListener("click", this.pauseSlides);
      });

      setTimeout(this.showSlides, 5000);
    },

    pauseSlides() {
      // Remove event listeners from each slide to pause the slideshow
      const slides = document.querySelectorAll(".slide-item");
      slides.forEach((slide) => {
        slide.removeEventListener("click", this.pauseSlides);
      });

      // Restart the slideshow after 5 seconds
      setTimeout(() => {
        slides.forEach((slide) => {
          slide.addEventListener("click", this.pauseSlides);
        });
        this.showSlides();
      }, 5000);
    },
  },
};
</script>

<style>
.banner-register-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  /* height: 100vh; */
}

.slide-item {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.slide-item img {
  width: 560px !important;
}

.slide-item .slide-title {
  color: #fff;
  font-weight: 600;
  font-size: 24px;
}

.slide-item .slide-caption {
  max-width: 560px;
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.16);
  color: #fff;
  padding: 6px 15px;
  font-size: 14px;
  font-weight: 500;
}

.slide-indicators .dot {
  height: 12px;
  width: 12px;
  margin: 0 2px;
  background-color: #fff;
  opacity: 0.5;
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.6s ease;
}

.slide-indicators .dot.active {
  background-color: #fff;
  opacity: 1;
}

/* Fading animation */
.overlay-fade {
  animation-name: overlay-fade;
  animation-duration: 1.5s;
  animation-fill-mode: both;
  animation-timing-function: ease-in-out;
}

@keyframes overlay-fade {
  from {
    opacity: 0.5;
    transform: translateX(100%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}
</style>
